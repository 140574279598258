export default {
  natours: {
    modalId: 'modal1',
    title: 'Natours',
    description: 'Advanced SASS implementation',
    github: 'https://github.com/SimasDei/udemy-css-one',
    img: require('../../assets/webScreens/natours.PNG'),
    fsimage: require('../../assets/fullWebScreens/natours-fullScreen.png'),
  },
  bookaroo: {
    modalId: 'modal2',
    title: 'Bookaroo',
    description: 'Mongodb React Express Node full stack application',
    github: 'https://github.com/SimasDei/bookaroo-client',
    img: require('../../assets/webScreens/bookarooToo.png'),
    fsimage: require('../../assets/fullWebScreens/bookaroo.png'),
  },
  bootstrap4: {
    modalId: 'modal3',
    title: 'Bootstrap',
    description: 'Made with Bootstrap v4.1',
    github: 'https://github.com/SimasDei/bootstrap-one',
    img: require('../../assets/webScreens/bootstrap-one.png'),
    fsimage: require('../../assets/fullWebScreens/bootstrap-one.png'),
  },
  burgerBuilder: {
    modalId: 'modal4',
    title: 'Burger Builder',
    description: 'Project made with the MERN stack',
    github: 'https://github.com/SimasDei/udemy-react-burger-builder',
    img: require('../../assets/webScreens/burger-builder.png'),
    fsimage: require('../../assets/fullWebScreens/burger-builder.png'),
  },
  meanStack: {
    modalId: 'modal5',
    title: 'MEAN stack',
    description: 'Made with Angular Node Express and MongoDB',
    github: 'https://github.com/SimasDei/MEAN-stack-one',
    img: require('../../assets/webScreens/MEAN-stack-one.png'),
    fsimage: require('../../assets/fullWebScreens/MEAN-stack-one.png'),
  },
  goodReads: {
    modalId: 'modal6',
    title: 'Good Reads',
    description: 'MERN stack app made using Good Reads API',
    github: 'https://github.com/SimasDei/mern-stack-three',
    img: require('../../assets/webScreens/MERN-goodreads-api.png'),
    fsimage: require('../../assets/fullWebScreens/MERN-goodreads-api.png'),
  },
  reactRecipes: {
    modalId: 'modal7',
    title: 'React Recipes',
    description: 'MERN stack app for recipes',
    github: 'https://github.com/SimasDei/baltic-js2course-22-fixed',
    img: require('../../assets/webScreens/MERN-recipe-app.png'),
    fsimage: require('../../assets/fullWebScreens/MERN-recipe-app.png'),
  },
  budgetApp: {
    modalId: 'modal8',
    title: 'Budget App',
    description: 'Monthly budget application, made with vanilla JS',
    github: 'https://github.com/SimasDei/budget-app',
    img: require('../../assets/webScreens/budget-app.png'),
    fsimage: require('../../assets/fullWebScreens/budget-app.png'),
  },
  weatherApp: {
    modalId: 'modal9',
    title: 'Weather App',
    description: 'Simple App made using vanilla JS and a weather API',
    github: 'https://github.com/SimasDei/weather-api-one',
    img: require('../../assets/webScreens/weather-app.png'),
    fsimage: require('../../assets/fullWebScreens/weather-app.png'),
  },
  githubApp: {
    modalId: 'modal10',
    title: 'Github App',
    description: 'Search Profile App working with Github API, vanilla JS',
    github: 'https://github.com/SimasDei/github-api-one',
    img: require('../../assets/webScreens/github-app.png'),
    fsimage: require('../../assets/fullWebScreens/github-app.png'),
  },
  hostingerFront: {
    modalId: 'modal11',
    title: 'Hostinger Front Page',
    description: 'Front Page made for Hostinger.com',
    github: 'https://github.com/SimasDei/task-frontend',
    img: require('../../assets/webScreens/hostinger-front.png'),
    fsimage: require('../../assets/fullWebScreens/hostinger-front.png'),
  },
};

export const data = [
  {
    project: 'natours',
    data: {
      modalId: 'modal1',
      title: 'Natours',
      description: 'Advanced SASS implementation',
      github: 'https://github.com/SimasDei/udemy-css-one',
      img: require('../../assets/webScreens/natours.PNG'),
      fsimage: require('../../assets/fullWebScreens/natours-fullScreen.png'),
    },
  },
  {
    project: 'bookaroo',
    data: {
      modalId: 'modal2',
      title: 'Bookaroo',
      description: 'Mongodb React Express Node full stack application',
      github: 'https://github.com/SimasDei/bookaroo-client',
      img: require('../../assets/webScreens/bookarooToo.png'),
      fsimage: require('../../assets/fullWebScreens/bookaroo.png'),
    },
  },
  {
    project: 'bootstrap4',
    data: {
      modalId: 'modal2',
      title: 'Bootstrap',
      description: 'Made with Bootstrap v4.1',
      github: 'https://github.com/SimasDei/bootstrap-one',
      img: require('../../assets/webScreens/bootstrap-one.png'),
      fsimage: require('../../assets/fullWebScreens/bootstrap-one.png'),
    },
  },
  {
    project: 'burgerBuilder',
    data: {
      modalId: 'modal4',
      title: 'Burger Builder',
      description: 'Project made with the MERN stack',
      github: 'https://github.com/SimasDei/udemy-react-burger-builder',
      img: require('../../assets/webScreens/burger-builder.png'),
      fsimage: require('../../assets/fullWebScreens/burger-builder.png'),
    },
  },
  {
    project: 'meanStack',
    data: {
      modalId: 'modal5',
      title: 'MEAN stack',
      description: 'Made with Angular Node Express and MongoDB',
      github: 'https://github.com/SimasDei/MEAN-stack-one',
      img: require('../../assets/webScreens/MEAN-stack-one.png'),
      fsimage: require('../../assets/fullWebScreens/MEAN-stack-one.png'),
    },
  },
  {
    project: 'goodReads',
    data: {
      modalId: 'modal6',
      title: 'Good Reads',
      description: 'MERN stack app made using Good Reads API',
      github: 'https://github.com/SimasDei/mern-stack-three',
      img: require('../../assets/webScreens/MERN-goodreads-api.png'),
      fsimage: require('../../assets/fullWebScreens/MERN-goodreads-api.png'),
    },
  },
  {
    project: 'reactRecipes',
    data: {
      modalId: 'modal7',
      title: 'React Recipes',
      description: 'MERN stack app for recipes',
      github: 'https://github.com/SimasDei/baltic-js2course-22-fixed',
      img: require('../../assets/webScreens/MERN-recipe-app.png'),
      fsimage: require('../../assets/fullWebScreens/MERN-recipe-app.png'),
    },
  },
  {
    project: 'budgetApp',
    data: {
      modalId: 'modal8',
      title: 'Budget App',
      description: 'Monthly budget application, made with vanilla JS',
      github: 'https://github.com/SimasDei/budget-app',
      img: require('../../assets/webScreens/budget-app.png'),
      fsimage: require('../../assets/fullWebScreens/budget-app.png'),
    },
  },
  {
    project: 'watherApp',
    data: {
      modalId: 'modal9',
      title: 'Weather App',
      description: 'Simple App made using vanilla JS and a weather API',
      github: 'https://github.com/SimasDei/weather-api-one',
      img: require('../../assets/webScreens/weather-app.png'),
      fsimage: require('../../assets/fullWebScreens/weather-app.png'),
    },
  },
  {
    project: 'githubApp',
    data: {
      modalId: 'modal10',
      title: 'Github App',
      description: 'Search Profile App working with Github API, vanilla JS',
      github: 'https://github.com/SimasDei/github-api-one',
      img: require('../../assets/webScreens/github-app.png'),
      fsimage: require('../../assets/fullWebScreens/github-app.png'),
    },
  },
  {
    project: 'hostingerFront',
    data: {
      modalId: 'modal11',
      title: 'Hostinger Front Page',
      description: 'Front Page made for Hostinger.com',
      github: 'https://github.com/SimasDei/task-frontend',
      img: require('../../assets/webScreens/hostinger-front.png'),
      fsimage: require('../../assets/fullWebScreens/hostinger-front.png'),
    },
  },
];
